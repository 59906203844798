#about {
	padding: 100px 0;

	&.no-padding {
		padding-bottom: 0; } }

.about-us {
	p {
		margin-bottom: 30px; } }

.about {
	border-bottom: 2px solid #dcdcdc;
	padding-bottom: 50px; }

.ab-feature-items {
	padding: 50px 0; }

.ab-feature {
	.feature-icon {
		height: 60px;
		width: 60px;
		background: $color_theme;
		line-height: 70px;
		text-align: center;
		border-radius: 50%;
		float: left;
		position: relative;

		i {
			font-size: 25px;
			color: #FFF; }

		&:before {
			position: absolute;
			content: '';
			height: 65px;
			width: 65px;
			border-radius: 50%;
			left: -3px;
			top: -3px;
			border: 5px solid #ffdada;
			z-index: 1; } }
	h3 {
		font-size: 20px;
		font-weight: 700;
		color: #000;
		margin: 20px 0 35px 70px;
		text-transform: uppercase; } }

.ab-thumb {
	max-width: 560px;
	margin: 0 auto;
	box-shadow: 0 10px 50px rgba(0,0,0,0.2);
	img {
		width: 100%; } }


.panel-default {
	border: none;
	box-shadow: none;
	border-radius: 0; }

.panel-group .panel+.panel {
	margin-top: 10px; }

.panel-default>.panel-heading {
	border: 1px solid #d7d7d7;
	border-radius: 0;
	box-shadow: none;
	background-color: transparent;
	padding: 13px 20px;
	transition: all 0.3s ease; }

.panel-default>.panel-heading.active {
	background-color: $color_theme; }

.panel-title {
	color: $color_theme;
	text-transform: none;
	font-family: 'Roboto', sans-serif; }

.panel-title a {
	color: $color_theme;
	position: relative;
	display: block;
	transition: all 0.3s ease; }

.panel-title a:focus {
	text-decoration: none; }

.active .panel-title a {
	color: #ffffff; }

.panel-title a:after {
	content: "\f107";
	font-family: "FontAwesome";
	right: 0;
	position: absolute; }

.active .panel-title a:after {
	content: "\f106"; }

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: none;
	background-color: #f7f7f7;
	padding: 25px 40px 25px 20px; }

.panel-body p {
	border-left: 4px solid $color_theme;
	padding-left: 18px;
	margin: 0; }

.panel-body p + p {
	margin-top: 25px; }



/*=========  About Two  ==========*/
#history {
	padding: 100px 0;

	.section-title {
		margin-bottom: 30px; }


	.gp-btn {
		margin-top: 30px; } }


/*=========  About Us Two  ==========*/
#about-us {
	padding: 100px 0;
	background: #f5f5f5; }

.feature-two {
	margin-top: 30px;
	.icon-box {
		height: 60px;
		width: 60px;
		line-height: 73px;
		float: left;
		border: 1px solid $color_theme;
		text-align: center;
		transition: all 0.3s ease-in-out;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			height: 104%;
			width: 5px;
			right: -6px;
			top: -1px;
			background: #ff6863;
			border-top-right-radius: 57px;
			border-bottom-right-radius: 57px; }

		i {
			font-size: 30px;
			color: $color_theme;
			transition: all 0.3s ease-in-out; } }


	.content {
		margin-left: 90px;

		h3 {
			font-size: 18px;
			margin-top: 0;
			margin-bottom: 5px;
			text-transform: uppercase;
			font-weight: 600;
			transition: all 0.3s ease-in-out; } }


	&:hover {
		.icon-box {
			background: $color_theme;

			i {
				color: #FFF; } }

		.content {
			h3 {
				color: $color_theme; } } } }


.mission {
	margin-top: 100px; }


.tab {
	position: relative;
	font-weight: 300;
	color: #888; }



.tabs {
	display: table;
	position: relative;
	margin: 0;
	padding: 0;
	// background: #0e0e0e
	overflow: visible;
	list-style: none;

	li {
		// line-height: 38px
		padding: 0;
		position: relative;
		border: 1px solid #343434;
		text-align: center;
		display: inline-block;
		margin-right: 40px;

		&:last-child {
			margin-right: 0; }
		a {

			color: #333;
			display: block;
			letter-spacing: 0;
			outline: none;
			padding: 12px 35px;
			text-decoration: none;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			text-transform: uppercase;
			font-weight: 700; }

		&.current {
			border-color: $color_theme;
			a {
				color: #fff;
				background: $color_theme;
				position: relative; }

			&:after {
				position: absolute;
				content: '';
				left: 50%;
				bottom: -10px;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-left: 13px solid transparent;
				border-right: 13px solid transparent;
				border-top: 10px solid $color_theme; } } } }

.tab_content {
	margin-top: 10px; }

.tabs_item {
	display: none;

	&:first-child {
		display: block; } }

.mission-feature {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		line-height: 35px;
		width: 50%;
		float: left;
		font-size: 16px;
		font-weight: 600;

		i {
			margin-right: 15px;
			color: $color_theme; } } }
