body {
  font-size: 14px;
  color: #000;
  font-family: 'Lato', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
}

.starter-template {
  padding: 40px 15px;
  text-align: center;
}

p {
  line-height: 24px;
}

a {
  transition: all 0.15s ease-in-out;
}

a,
a:hover {
  text-decoration: none;
}

.no-padding {
  padding-bottom: 0;
}

.gp-btn {
  display: inline-block;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  padding: 14px 35px;
  cursor: pointer;
  background: #000;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  border-radius: 3px;
}

.gp-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ff5722;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.gp-btn.gp-bn-active {
  background: #ff5722;
  padding: 15px 50px;
}

.gp-btn.gp-bn-active:before {
  background: #000;
}

.gp-btn.btn-radius {
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.gp-btn.btn-radius:before {
  display: none;
}

.gp-btn.btn-radius:hover {
  background: #ff5722;
}

.gp-btn:hover, .gp-btn:focus {
  color: #FFF;
  text-decoration: none;
}

.gp-btn:hover:before, .gp-btn:focus:before {
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.gp-active {
  background: #ff5722;
  margin-right: 20px;
}

.section-title {
  position: relative;
}

.section-title h2 {
  color: #000;
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 0;
  position: relative;
  z-index: 100;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}

.section-title h2:before {
  position: absolute;
  content: '';
  background: #ff5722;
  width: 50px;
  height: 4px;
  left: 50%;
  bottom: -18px;
  z-index: 100;
  transform: translateX(-50%);
}

.section-title h2:after {
  position: absolute;
  content: '';
  background: #c5c5c5;
  width: 100%;
  height: 1px;
  right: 0;
  bottom: -17px;
}

.section-title p {
  margin-bottom: 50px;
}

.section-title.style-left h2:before {
  left: 0;
  transform: translateX(0);
}

/*=========  Remove This Css  ==========*/
.demo-page {
  margin: 0;
  padding: 0;
  list-style: none;
}

.demo-page li {
  margin-bottom: 20px;
  position: relative;
}

.demo-page li:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.demo-page li a {
  display: block;
  text-align: center;
  color: #FFF;
}

.demo-page li a img {
  width: 100%;
}

.demo-page li a strong {
  z-index: 100;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

.demo-page li:hover:after {
  background: rgba(0, 0, 0, 0.8);
}

.demo-page li:hover a strong {
  opacity: 1;
  transform: translateY(-50%);
}

.Switcher__control {
  background-image: none;
  background: #ff5722;
}

.Switcher__control i {
  font-size: 30px;
  color: #FFF;
}

/*=========  Page Loader  ==========*/
#loader {
  width: 100%;
  height: 100%;
  background: #333;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999;
}

.loaded {
  display: none;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loader--glisteningWindow {
  width: 0.25em;
  height: 0.25em;
  box-shadow: 0.70711em 0.70711em 0 0em #2ecc71, -0.70711em 0.70711em 0 0.17678em #9b59b6, -0.70711em -0.70711em 0 0.25em #3498db, 0.70711em -0.70711em 0 0.17678em #f1c40f;
  animation: gw 1s ease-in-out infinite, rot 2.8s linear infinite;
}

@keyframes rot {
  to {
    transform: rotate(360deg);
  }
}

@keyframes gw {
  0% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f;
  }
  25% {
    box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.5em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.125em #f1c40f;
  }
  50% {
    box-shadow: 0.70711em 0.70711em 0 0.5em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.125em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f;
  }
  75% {
    box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.125em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.5em #f1c40f;
  }
  100% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f;
  }
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 1000;
  border-bottom: 1px solid #333;
}

#header.navbar-small {
  position: fixed;
  background: #FFF;
  border-bottom: none;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
}

#header.navbar-small .nav li.active a {
  color: #ff5722;
}

#header.navbar-small .nav li a {
  color: #000;
  padding: 20px 15px;
}

#header.navbar-small .nav li a:hover {
  color: #ff5722;
}

#header.navbar-small .navbar-brand {
  padding: 5px 30px 5px 0;
  border-color: #ccc;
}

#header.navbar-small .navbar-brand .logo-light {
  display: none;
}

#header.navbar-small .navbar-brand .logo-dark {
  display: block;
}

.navbar {
  margin: 0;
  border-bottom: none;
}

.navbar-brand {
  height: auto;
  padding: 15px 30px 15px 0;
  border-right: 1px solid #333;
}

.navbar-brand .logo-light {
  display: block;
}

.navbar-brand .logo-dark {
  display: none;
}

.nav > li > a {
  color: #FFF;
  padding: 30px 15px;
  text-transform: uppercase;
  font-size: 12px;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
  color: #ff5722;
}

.nav > li.search-btn a {
  position: relative;
}

.nav > li.search-btn a:before {
  position: absolute;
  left: 0;
  top: 32px;
  height: 14px;
  width: 2px;
  background: #FFF;
  content: '';
}

.navbar-toggle {
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background: #FFF;
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
/* Banner */
.swiper-banner {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.swiper-banner .swiper-slide .slider-caption-one, .swiper-banner .swiper-slide .slider-caption-two, .swiper-banner .swiper-slide .slider-caption-three {
  opacity: 0;
  transition-delay: .7s;
}

.swiper-banner .swiper-slide-active .slider-caption-one, .swiper-banner .swiper-slide-active .slider-caption-two, .swiper-banner .swiper-slide-active .slider-caption-three {
  opacity: 1;
}

.swiper-banner .swiper-slide {
  background-size: cover;
}

.swiper-banner .swiper-slide > img {
  width: 100%;
  height: auto;
}

.swiper-banner .swiper-slide .slider-caption h3 {
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.swiper-banner .swiper-slide .slider-caption h2 {
  font-size: 50px;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 40px;
}

.swiper-banner .swiper-slide .slider-caption p {
  color: #FFF;
  line-height: 26px;
  margin-bottom: 50px;
}

.banner-next, .banner-prev {
  top: 50%;
  position: absolute;
  z-index: 500;
  color: #FFF;
  background: rgba(0, 0, 0, 0.7);
  height: 50px;
  width: 50px;
  line-height: 60px;
  transition: all 0.3s ease;
  text-align: center;
  opacity: 0;
  overflow: hidden;
  cursor: pointer;
}

.banner-next .next-btn i, .banner-next .prev-btn i, .banner-prev .next-btn i, .banner-prev .prev-btn i {
  font-size: 40px;
}

.banner-next:hover, .banner-prev:hover {
  background: #ff5722;
}

.banner-prev {
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}

.banner-next {
  right: 0%;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.swiper-pagination {
  text-align: right;
  right: 100px;
  left: auto !important;
}

.swiper-pagination-bullet {
  width: 3px;
  height: 20px;
  border-radius: 0;
  background: #FFF;
  opacity: 1;
  margin: 0 2px !important;
}

.swiper-pagination-bullet-active {
  background: #ff5722;
  height: 25px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.slider-caption-one {
  text-align: center;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 100%;
  position: absolute;
}

.slider-caption-one h2 {
  font-size: 100px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  position: relative;
  display: block;
  line-height: 100px;
  letter-spacing: 1px;
}

.slider-caption-one h3 {
  font-size: 22px;
  color: #FFF;
  margin-bottom: 50px;
  letter-spacing: 1px;
}

.slider-caption-one h4 {
  font-size: 20px;
  color: #FFF;
}

.slider-caption-two {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
}

.slider-caption-two h4 {
  color: #FFF;
  font-size: 28px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.slider-caption-two h2 {
  font-size: 70px;
  line-height: 70px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 0;
}

.slider-caption-two p {
  color: #FFF;
  font-size: 16px;
  margin: 20px 0 40px;
}

.slider-caption-three {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.slider-caption-three h3 {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-weight: 600;
}

.slider-caption-three h4 {
  color: #FFF;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.slider-caption-three h2 {
  font-size: 60px;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.slider-caption-three p {
  color: #ddd;
  font-size: 15px;
  margin-bottom: 30px;
}

.switcher {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  display: block;
  animation: move 2.5s infinite;
}

.switcher i {
  color: #FFF;
  font-size: 30px;
}

.switcher:hover {
  text-decoration: none;
}

.swiper-banner:hover .banner-next, .swiper-banner:hover .banner-prev {
  opacity: 1;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.tp-bannertimer {
  display: none;
}

/*=========  Banner Video  ==========*/
#banner-video {
  height: 100vh;
  position: relative;
}

#banner-video .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.video-content {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.video-content h2 {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
  color: #FFF;
}

.video-content h2 span {
  color: #ff5722;
}

.video-content p {
  color: #bbb;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 40px;
}

/*=========  Banner Image  ==========*/
#banner-image {
  height: 100vh;
  position: relative;
  background-size: cover;
}

#banner-image .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#banner-image .banner-content {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

#banner-image .banner-content h3 {
  font-size: 30px;
  color: #FFF;
  margin: 0;
  font-weight: 700;
}

#banner-image .banner-content h2 {
  color: #FFF;
  font-size: 110px;
  margin: 0;
  font-weight: 700;
}

#banner-image .banner-content h4 {
  font-size: 20px;
  color: #ddd;
  margin: 20px 0 30px;
  letter-spacing: 3px;
}

/*--------------------------------------------------------------
  ##  About Us
  --------------------------------------------------------------*/
#about {
  padding: 100px 0;
}

#about.no-padding {
  padding-bottom: 0;
}

.about-us p {
  margin-bottom: 30px;
}

.about {
  border-bottom: 2px solid #dcdcdc;
  padding-bottom: 50px;
}

.ab-feature-items {
  padding: 50px 0;
}

.ab-feature .feature-icon {
  height: 60px;
  width: 60px;
  background: #ff5722;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  float: left;
  position: relative;
}

.ab-feature .feature-icon i {
  font-size: 25px;
  color: #FFF;
}

.ab-feature .feature-icon:before {
  position: absolute;
  content: '';
  height: 65px;
  width: 65px;
  border-radius: 50%;
  left: -3px;
  top: -3px;
  border: 5px solid #ffdada;
  z-index: 1;
}

.ab-feature h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 20px 0 35px 70px;
  text-transform: uppercase;
}

.ab-thumb {
  max-width: 560px;
  margin: 0 auto;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
}

.ab-thumb img {
  width: 100%;
}

.panel-default {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.panel-group .panel + .panel {
  margin-top: 10px;
}

.panel-default > .panel-heading {
  border: 1px solid #d7d7d7;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 13px 20px;
  transition: all 0.3s ease;
}

.panel-default > .panel-heading.active {
  background-color: #ff5722;
}

.panel-title {
  color: #ff5722;
  text-transform: none;
  font-family: 'Roboto', sans-serif;
}

.panel-title a {
  color: #ff5722;
  position: relative;
  display: block;
  transition: all 0.3s ease;
}

.panel-title a:focus {
  text-decoration: none;
}

.active .panel-title a {
  color: #ffffff;
}

.panel-title a:after {
  content: "\f107";
  font-family: "FontAwesome";
  right: 0;
  position: absolute;
}

.active .panel-title a:after {
  content: "\f106";
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
  background-color: #f7f7f7;
  padding: 25px 40px 25px 20px;
}

.panel-body p {
  border-left: 4px solid #ff5722;
  padding-left: 18px;
  margin: 0;
}

.panel-body p + p {
  margin-top: 25px;
}

/*=========  About Two  ==========*/
#history {
  padding: 100px 0;
}

#history .section-title {
  margin-bottom: 30px;
}

#history .gp-btn {
  margin-top: 30px;
}

/*=========  About Us Two  ==========*/
#about-us {
  padding: 100px 0;
  background: #f5f5f5;
}

.feature-two {
  margin-top: 30px;
}

.feature-two .icon-box {
  height: 60px;
  width: 60px;
  line-height: 73px;
  float: left;
  border: 1px solid #ff5722;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.feature-two .icon-box:after {
  position: absolute;
  content: '';
  height: 104%;
  width: 5px;
  right: -6px;
  top: -1px;
  background: #ff6863;
  border-top-right-radius: 57px;
  border-bottom-right-radius: 57px;
}

.feature-two .icon-box i {
  font-size: 30px;
  color: #ff5722;
  transition: all 0.3s ease-in-out;
}

.feature-two .content {
  margin-left: 90px;
}

.feature-two .content h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.feature-two:hover .icon-box {
  background: #ff5722;
}

.feature-two:hover .icon-box i {
  color: #FFF;
}

.feature-two:hover .content h3 {
  color: #ff5722;
}

.mission {
  margin-top: 100px;
}

.tab {
  position: relative;
  font-weight: 300;
  color: #888;
}

.tabs {
  display: table;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: visible;
  list-style: none;
}

.tabs li {
  padding: 0;
  position: relative;
  border: 1px solid #343434;
  text-align: center;
  display: inline-block;
  margin-right: 40px;
}

.tabs li:last-child {
  margin-right: 0;
}

.tabs li a {
  color: #333;
  display: block;
  letter-spacing: 0;
  outline: none;
  padding: 12px 35px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 700;
}

.tabs li.current {
  border-color: #ff5722;
}

.tabs li.current a {
  color: #fff;
  background: #ff5722;
  position: relative;
}

.tabs li.current:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 10px solid #ff5722;
}

.tab_content {
  margin-top: 10px;
}

.tabs_item {
  display: none;
}

.tabs_item:first-child {
  display: block;
}

.mission-feature {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mission-feature li {
  line-height: 35px;
  width: 50%;
  float: left;
  font-size: 16px;
  font-weight: 600;
}

.mission-feature li i {
  margin-right: 15px;
  color: #ff5722;
}

/*--------------------------------------------------------------
  ##  Video
  --------------------------------------------------------------*/
#video {
  padding: 150px 0;
}

.video-play-icon {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 37px;
  font-size: 6rem;
  background-color: #ff5722;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.video-play-icon:focus {
  outline: none;
}

.how-it-works .video-play-icon {
  margin-top: 30px;
}

.video-play-icon i {
  margin-left: 5px;
  font-size: 30px;
  color: #FFF;
}

.intro-title {
  text-transform: uppercase;
  color: #FFF;
  font-weight: 700;
  margin-top: 30px;
}

/*--------------------------------------------------------------
  ##  Fetured Work
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
#countup {
  padding: 100px 0;
  background-size: cover;
}

.counter-box-two i {
  font-size: 50px;
  color: #ff5722;
}

.counter-box-two h2 {
  font-size: 50px;
  font-weight: 900;
  color: #FFF;
  margin-bottom: 0;
  line-height: 50px;
}

.counter-box-two p {
  color: #f7f7f7;
  font: 16px;
}

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
#service {
  padding: 100px 0 50px;
}

.feature-one {
  margin-bottom: 50px;
}

.feature-one .ser-icon {
  float: left;
  color: #ff5722;
}

.feature-one .ser-icon path {
  stroke: #ff5722;
}

.feature-one .content {
  margin-left: 80px;
}

.feature-one h3 {
  margin-top: 0;
  text-transform: uppercase;
  color: #333;
  font-size: 20px;
  font-weight: 300;
  position: relative;
  padding-bottom: 10px;
}

.feature-one h3:after {
  position: absolute;
  content: '';
  background: #ff5722;
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
}

/*=========  Service two  ==========*/
.feature-three {
  margin-bottom: 50px;
}

.feature-three .icon-box {
  height: 60px;
  width: 60px;
  transition: all 0.3s ease-in-out;
  line-height: 75px;
  text-align: center;
  border-radius: 5px;
  color: #333;
  float: left;
  border: 1px solid #ff5722;
  border-radius: 5px;
}

.feature-three .icon-box i {
  font-size: 30px;
  color: #ff5722;
}

.feature-three .content {
  margin-left: 70px;
}

.feature-three .content h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
  position: relative;
  padding-bottom: 5px;
}

.feature-three .content h3:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  background: #ff5722;
  height: 2px;
  width: 50px;
}

.feature-three:hover .icon-box {
  background: #ff5722;
}

.feature-three:hover .icon-box i {
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Work Together
  --------------------------------------------------------------*/
#work-together {
  padding: 100px 0;
  text-align: center;
}

.together h2 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 20px;
}

.together p {
  color: #ccc;
  margin-bottom: 30px;
}

.together .gp-btn {
  padding: 13px 30px;
  background: #FFF;
  color: #000;
}

.together .gp-btn:hover {
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
#skills {
  padding: 100px 0;
}

.skills {
  padding-bottom: 50px;
  border-bottom: 2px solid #dedede;
}

.count-items {
  margin-top: 50px;
}

.progress {
  border-radius: 0;
  height: 5px;
  margin-bottom: 40px;
  overflow: visible;
  box-shadow: none;
  background: #eee;
  margin-bottom: 46px;
}

.progress.progress-two {
  border: 1px solid #bfbfbf;
  background-color: transparent;
  height: 10px;
}

.progress.progress-two .progress-bar {
  height: 4px;
  margin: 2px;
  margin-bottom: 50px;
}

.progress.progress-two .progress-bar:after {
  display: none;
}

.skill-bar {
  text-align: left !important;
  margin: 0 !important;
  padding-left: 0;
  list-style: none;
}

.skill-bar li .skill-bar-wrap {
  margin-bottom: 20px;
  box-shadow: none;
}

.skill-bar li .progress-bar {
  height: 5px;
  width: 0;
  display: block;
  text-align: right;
  overflow: visible !important;
  position: relative;
  box-shadow: none;
  border-radius: 0 !important;
  opacity: 1;
  background: #ff5722;
}

.skill-bar li .progress-bar:after {
  position: absolute;
  top: -8px;
  right: 5px;
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #ff5722;
}

.skill-bar li .skill-name, .skill-bar li .percentage {
  color: #000;
  position: relative;
  top: -25px;
}

.skill-bar li .skill-name {
  position: absolute;
  left: 0;
  font-weight: 700;
  font-size: 14px;
}

.counter-box .count h2 {
  font-size: 40px;
  font-weight: 800;
  padding-bottom: 10px;
  position: relative;
}

.counter-box .count h2:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 40px;
  background: #ff5722;
  transform: translateX(-50%);
}

.counter-box .count p {
  color: #333;
  font-weight: 600;
  font-style: italic;
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
#team {
  padding: 100px 0;
  background: #f5f5f5;
}

.team-members {
  position: relative;
  box-shadow: 0 3px 20px rgba(50, 50, 50, 0.5);
  max-width: 400px;
  margin: 0 auto 20px;
}

.team-members .team-thumb img {
  width: 100%;
}

.team-members .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.team-members .merber-profile {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 43%;
}

.team-members .merber-profile:after, .team-members .merber-profile:before {
  position: absolute;
  height: 2px;
  width: 0;
  content: '';
  background: #ff5722;
  top: 51px;
  left: 53px;
  transition: all 0.5s ease-in-out;
  width: 1px;
}

.team-members .merber-profile:before {
  transform: rotate(-45deg);
}

.team-members .merber-profile:after {
  transform: rotate(45deg);
}

.team-members .merber-profile li {
  width: 50%;
  height: 35px;
  width: 35px;
  position: relative;
  text-align: center;
  line-height: 35px;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 45px;
  position: absolute;
}

.team-members .merber-profile li:nth-child(1) {
  left: 35px;
  top: -10px;
}

.team-members .merber-profile li:nth-child(2) {
  left: 82px;
  top: 34px;
}

.team-members .merber-profile li:nth-child(3) {
  left: -10px;
  top: 34px;
}

.team-members .merber-profile li:nth-child(4) {
  left: 35px;
  top: 80px;
}

.team-members .merber-profile li a {
  color: #FFF;
  display: block;
  cursor: pointer;
}

.team-members .merber-profile li:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.team-members .merber-profile li:after {
  position: absolute;
  content: '';
  height: 35px;
  width: 35px;
  border: 1px solid #FFF;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
}

.team-members .merber-profile li:hover a {
  color: #FFF;
}

.team-members .merber-profile li:hover a i {
  z-index: 100;
}

.team-members .merber-profile li:hover:before {
  background: #ff5722;
}

.team-members .merber-profile li:hover:after {
  border-color: #ff5722;
}

.team-members .details {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.team-members .name {
  transform: translateY(15px);
  font-size: 18px;
  margin: 0px;
  color: #ff5722;
  transition: all 0.3s ease-in-out;
}

.team-members .position {
  font-size: 15px;
  color: #FFF;
  transform: translateY(15px);
  transition: all 0.5s ease-in-out;
  margin-bottom: 0;
}

.team-members:hover .content {
  opacity: 1;
}

.team-members:hover .content .merber-profile:after, .team-members:hover .content .merber-profile:before {
  width: 100px;
  top: 51px;
  left: 3px;
}

.team-members:hover .content .merber-profile li:nth-child(1) {
  top: 0;
}

.team-members:hover .content .merber-profile li:nth-child(2) {
  left: 72px;
}

.team-members:hover .content .merber-profile li:nth-child(3) {
  left: 0;
}

.team-members:hover .content .merber-profile li:nth-child(4) {
  top: 70px;
}

.team-members:hover .content .name, .team-members:hover .content .position {
  transform: translateY(0);
}

/*--------------------------------------------------------------
  ##  Choose
  --------------------------------------------------------------*/
#choose {
  padding: 100px 0;
}

.choose {
  margin-top: 50px;
}

.choose-thumb {
  max-width: 500px;
  margin: 0 auto;
}

.choose-thumb img {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 70px 0;
  text-align: center;
  background: #f5f5f5;
  position: relative;
  background-size: cover;
}

#testimonial .section-title h2 {
  color: #FFF;
}

#testimonial p {
  color: #FFF;
}

.testimonial-slider {
  margin-top: 20px;
}

.testimonial .content > i {
  font-size: 40px;
  color: #FFF;
  margin-bottom: 15px;
}

.testimonial .content h3 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ff5722;
  margin-top: 0;
  margin-bottom: 10px;
}

.testimonial .content span {
  color: #999;
  font-size: 14px;
}

.testimonial .content .star {
  margin: 10px 0 20px;
  padding: 0;
}

.testimonial .content .star li {
  display: inline-block;
  margin: 0 5px;
}

.testimonial .content .star li i {
  color: #ff5722;
  font-size: 18px;
}

.testimonial .content p {
  font-size: 14px;
  margin-bottom: 30px;
}

.testimonial .content p i {
  color: #ff5722;
  font-size: 20px;
}

.testimonial .testi-thumb {
  float: right;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #ff5722;
}

.tm-control {
  width: 100px;
  position: absolute;
  right: 25px;
  bottom: -60px;
}

.tm-control .tm-prev, .tm-control .tm-next {
  height: 40px;
  width: 40px;
  border: 1px solid #FFF;
  line-height: 55px;
  cursor: pointer;
}

.tm-control .tm-prev i, .tm-control .tm-next i {
  font-size: 30px;
  color: #FFF;
}

.tm-control .tm-prev {
  float: left;
}

.tm-control .tm-next {
  float: right;
}

/*=========  Testimonial Two  ==========*/
#testimonial.testimonial-two .testi-wrapper {
  width: 100%;
  position: relative;
}

#testimonial.testimonial-two .testimonial .content {
  float: none;
}

#testimonial.testimonial-two .testimonial .testi-thumb {
  float: none;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
}

#testimonial.testimonial-two .testimonial .testi-thumb img {
  width: 100%;
}

#testimonial.testimonial-two .tm-control {
  bottom: 40px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

/*--------------------------------------------------------------
  ##  Brand
  --------------------------------------------------------------*/
#brand {
  padding: 80px 0;
}

#brand .brand-container {
  overflow: hidden;
  margin-top: 30px;
}

#brand .brand-container .swiper-slide {
  background: #f5f5f5;
}

.brand-two {
  background: #ff5722;
}

.brand-two h2 {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.brand-two .client-thumb {
  background: #fff;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
  background: #f2f2f2;
}

.pricing-item {
  border: 1px solid #f5f5f5;
  text-align: center;
  padding-bottom: 30px;
  box-shadow: 0 5px 31px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  background: #fff;
  max-width: 400px;
}

.pricing-item .price-active {
  position: absolute;
  right: 0px;
  top: 19px;
  background: #ff6863;
  z-index: 100;
  padding: 7px 10px 7px 25px;
  font-size: 14px;
  color: #FFF;
  transform: rotate(-90deg);
}

.pricing-item .price-active:after {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #f7f7f7;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
}

.pricing-item .pricing-head {
  height: 150px;
  background: #f7f7f7;
  padding: 20px 0;
  position: relative;
  margin-bottom: 70px;
}

.pricing-item .pricing-head h3 {
  margin-top: 0;
  text-transform: uppercase;
  color: #ff5722;
  font-weight: 700;
}

.pricing-item .pricing-head .price {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background: #ff5722;
  line-height: 20px;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  padding: 30px 0;
}

.pricing-item .pricing-head .price:before {
  position: absolute;
  content: '';
  left: 5px;
  top: 5px;
  height: 100px;
  width: 100px;
  border: 2px solid #FFF;
  border-radius: 50%;
}

.pricing-item .pricing-head .price h5 {
  color: #FFF;
  font-size: 30px;
  margin: 0;
  font-weight: 700;
}

.pricing-item .pricing-head .price span {
  color: #fff;
}

.pricing-item .price-feature {
  margin-bottom: 20px;
}

.pricing-item .price-feature .price-feature-item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-item .price-feature .price-feature-item li {
  padding: 5px 0;
  font-size: 15px;
}

.pricing-item .gp-btn {
  padding: 12px 40px;
  border-radius: 2px;
  background: #ff5722;
}

.pricing-item .gp-btn:before {
  transition: all 0.3s ease-in-out;
}

.pricing-item .gp-btn:hover:before {
  background: #333;
}

.pricing-item:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

/*--------------------------------------------------------------
  ##  Brand
  --------------------------------------------------------------*/
#brand {
  padding: 80px 0;
}

#brand .brand-container {
  overflow: hidden;
  margin-top: 30px;
}

#brand .brand-container .swiper-slide {
  background: #f5f5f5;
}

.brand-two {
  background: #ff5722;
}

.brand-two h2 {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.brand-two .client-thumb {
  background: #fff;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog {
  padding: 100px 0;
  background: #f5f5f5;
}

.blog {
  background: #FFF;
  box-shadow: 0 10px 30px rgba(50, 50, 50, 0.1);
  max-width: 400px;
}

.blog .blog-thumb img {
  width: 100%;
}

.blog .blog-content {
  padding: 20px;
}

.blog .blog-content .blog-title {
  margin-top: 0;
  font-size: 20px;
  text-transform: uppercase;
}

.blog .blog-content .blog-title a {
  color: #333;
  transition: all 0.3s ease-in-out;
}

.blog .blog-content .blog-title a:hover {
  color: #ff5722;
}

.blog .post-meta {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

.blog .post-meta li {
  display: inline-block;
  margin-right: 15px;
  color: #333;
  font: 14px;
  position: relative;
}

.blog .post-meta li a {
  color: #a2a2a2;
}

.blog .post-meta li:after {
  position: absolute;
  right: -12px;
  top: -1px;
  content: '/';
}

.blog .post-meta li:last-child:after {
  display: none;
}

/*--------------------------------------------------------------
  ##  News Latter
  --------------------------------------------------------------*/
#news-letter {
  padding: 100px 0;
  text-align: center;
  background-size: cover;
}

.news-letter h2 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
}

.news-letter p {
  margin-bottom: 30px;
  color: #FFF;
}

.news-form input {
  width: 400px;
  height: 48px;
  background: transparent;
  border: 1px solid #ccc;
  padding: 10px;
  color: #FFF;
  outline: none;
  font-size: 16px;
}

.news-form .gp-btn {
  border: none;
  background: #FFF;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  outline: none;
}

.news-form .gp-btn:hover, .news-form .gp-btn:focus {
  background: #fff;
  color: #fff;
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact {
  background: #2a2a2a;
}

.contact-form-area {
  padding: 70px 0;
}

.copy-right {
  padding: 25px;
  background: #212121;
}

.copy-right p {
  margin: 0;
  color: #969696;
  font-size: 14px;
  font-weight: 600;
}

.copy-right p a {
  color: #ff5722;
  text-decoration: none;
}

.contact-title {
  font-size: 17px;
  font-weight: 600;
  color: #FFF;
  position: relative;
  padding-bottom: 10px;
  margin-top: 0;
}

.contact-title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 40px;
  height: 2px;
  background: #ff5722;
}

.con-details {
  margin-bottom: 30px;
}

.con-details p {
  color: #ababab;
  margin-bottom: 0;
  font-size: 15px;
}

.get-in-touch h2 {
  font-size: 20px;
  font-weight: 600;
  color: #FFF;
  position: relative;
  padding-bottom: 10px;
  margin-top: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.get-in-touch h2:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 40px;
  height: 2px;
  background: #ff5722;
}

#gp-contact-form .form-control {
  background: transparent;
  border-radius: 0;
  height: 40px;
  border: 1px solid #555;
  transition: all 0.3s ease-in-out;
}

#gp-contact-form .form-control:hover, #gp-contact-form .form-control:focus {
  box-shadow: none;
  border-color: #ff5722;
}

#gp-contact-form textarea.form-control {
  height: 150px;
}

/*--------------------------------------------------------------
  ##  Portfolio
  --------------------------------------------------------------*/
#portfolio {
  position: relative;
  padding-top: 100px;
}

#projects-grid {
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 25px;
}

#projects-grid:after {
  content: '';
  display: block;
  clear: both;
}

.portfolio-item {
  position: relative;
  float: left;
  width: 33.3333333333%;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: -1px;
  transform: translate3d(1px, 0, 0);
}

.portfolio-item h3 {
  position: absolute;
  left: 25px;
  bottom: 40px;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  z-index: 3;
  color: #fff;
  opacity: 0;
  text-transform: uppercase;
  transition: all 300ms linear;
}

.portfolio-item h3 span {
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 2px;
}

.portfolio-item img {
  width: 100%;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.portfolio-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #212121;
  opacity: 0;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.portfolio-item:hover h3 {
  left: 40px;
  opacity: 1;
}

.portfolio-item:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.portfolio-item:hover .overlay {
  opacity: 0.9;
}

#portfolio-filter {
  text-align: center;
  z-index: 20;
  border-radius: 30px;
}

#filter {
  position: relative;
  width: 100%;
  text-align: center;
}

#filter li {
  display: inline-block;
  text-align: center;
}

#filter li a {
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 400;
  padding: 10px 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  outline: none;
  color: #fff;
  border-radius: 2px;
  background-color: #212121;
  text-decoration: none;
  transition: all 300ms linear;
  text-transform: uppercase;
}

#filter li a.current, #filter li a a:hover {
  background: #ff5722;
}

#filter:after {
  content: '';
  display: block;
  clear: both;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope, .isotope .isotope-item {
  transition-duration: 0.6s;
}

.isotope {
  transition-property: height, width;
}

.isotope .isotope-item {
  transition-property: transform, opacity;
}

.portfolio {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1320px;
}

.expander-wrap {
  position: relative;
  display: none;
  text-align: center;
}

#expander-wrap {
  position: relative;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  overflow: hidden;
}

/* Expander Item Controls */
.project-controls {
  position: relative;
  width: 100%;
  height: auto;
  top: 50px;
  max-width: 1320px;
  margin: 0 auto;
}

.expander-inner {
  position: relative;
}

.cls-btn {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  display: block;
  height: auto;
}

.expander-wrap .cls-btn .close {
  position: relative;
  display: inline-block;
  color: #000;
  top: 0;
  margin-top: 60px;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  background-image: url("../images/close.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  text-align: center;
  cursor: pointer;
  float: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.expander-close:hover {
  cursor: pointer;
}

.expander-wrap .cls-btn .close:hover {
  background-size: 35px 35px;
}

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@media screen and (max-width: 1440px) {
  /*=========  Banner  ==========*/
  /* Banner Image */
  #banner-image .banner-content h2 {
    font-size: 70px;
  }
  /* Slider */
  .slider-caption-one h2 {
    font-size: 80px;
    line-height: 80px;
  }
}

@media screen and (max-width: 1024px) {
  /*=========  Banner  ==========*/
  #banner-image .banner-content h2 {
    font-size: 70px;
  }
  #banner-image .banner-content h4 {
    font-size: 18px;
    letter-spacing: 0;
  }
  .video-content h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  /*=========  Header  ==========*/
  #header {
    background: rgba(0, 0, 0, 0.8);
  }
  #header.navbar-small .navbar-toggle .icon-bar {
    background: #000;
  }
  #header.navbar-small .nav li a {
    padding: 8px 15px;
  }
  .navbar-brand {
    padding: 5px 20px 5px 0;
  }
  .navbar-brand img {
    width: 120px;
  }
  .nav > li > a {
    padding: 10px;
  }
  .nav > li.search-btn a:before {
    display: none;
  }
  /*=========  Banner  ==========*/
  .gp-btn {
    padding: 10px 20px;
  }
  .gp-btn.gp-bn-active {
    padding: 10px 20px;
  }
  .slider-caption-one h2 {
    font-size: 40px;
    line-height: 30px;
  }
  .slider-caption-one h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .switcher {
    bottom: 20px;
  }
  #banner-image .banner-content h2 {
    font-size: 50px;
  }
  #banner-image .banner-content h4 {
    margin: 7px 0 20px;
  }
  .video-content h2 {
    font-size: 35px;
  }
  .video-content p {
    margin-bottom: 20px;
  }
  /*=========  About  ==========*/
  #about {
    padding: 50px 0;
  }
  .about-us .gp-btn {
    margin-bottom: 20px;
  }
  .ab-feature {
    max-width: 400px;
    margin: 0 auto 20px;
  }
  #history {
    padding: 50px 0;
  }
  #history .gp-btn {
    margin-bottom: 30px;
  }
  .feature-two {
    max-width: 400px;
    margin: 0 auto 30px;
  }
  .tab {
    margin-bottom: 50px;
  }
  /*=========  Video  ==========*/
  #video {
    padding: 70px 0;
  }
  /*=========  Service  ==========*/
  #service {
    padding: 50px 0;
  }
  .feature-one {
    max-width: 400px;
    margin: 0 auto 30px;
  }
  .feature-three {
    max-width: 400px;
    margin: 0 auto 30px;
  }
  /*=========  Countbox  ==========*/
  #countup {
    padding: 50px 0;
  }
  .counter-box-two {
    margin-bottom: 30px;
  }
  /*=========  Team  ==========*/
  .team-members {
    margin: 0 auto 20px;
  }
  .team-members .merber-profile {
    left: 59%;
    top: 30%;
  }
  /*=========  Pricing  ==========*/
  .pricing-item {
    margin: 0 auto 20px;
  }
  /*=========  Blog Grid  ==========*/
  .blog {
    margin: 0 auto 20px;
  }
  /*=========  News Letter  ==========*/
  .news-form input {
    width: 350px;
  }
}

@media screen and (max-width: 500px) {
  /*=========  Banner  ==========*/
  .slider-caption-one h2 {
    font-size: 35px;
    line-height: 28px;
  }
  .slider-caption-one h3 {
    font-size: 16px;
  }
  #banner-image .banner-content h2 {
    font-size: 40px;
  }
  .video-content p br {
    display: none;
  }
  /*=========  About  ==========*/
  .tabs li {
    margin-right: 5px;
  }
  .tabs li a {
    padding: 10px;
  }
  .mission-feature li {
    font-size: 13px;
    line-height: 20px;
  }
  /*=========  Portfolio  ==========*/
  #filter {
    padding-left: 0;
  }
  #filter li a {
    padding: 10px;
  }
  /*=========  Testimonial  ==========*/
  .testimonial .content p br {
    display: none;
  }
  /*=========  Newsletter  ==========*/
  .news-form input {
    width: 200px;
  }
  .news-form .gp-btn {
    padding: 15px 16px;
  }
}
