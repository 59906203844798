#blog {
	padding: 100px 0;
	background: #f5f5f5; }

.blog {
	background: #FFF;
	box-shadow: 0 10px 30px rgba(50,50,50,0.1);
	max-width: 400px;

	.blog-thumb {
		img {
			width: 100%; } }

	.blog-content {
		padding: 20px;
		.blog-title {
			margin-top: 0;
			font-size: 20px;
			text-transform: uppercase;

			a {
				color: #333;
				transition: all 0.3s ease-in-out;

				&:hover {
					color: $color_theme; } } } }

	.post-meta {
		margin: 0 0 20px 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 15px;
			color: #333;
			font: 14px;
			position: relative;

			a {
				color: #a2a2a2; }

			&:after {
				position: absolute;
				right: -12px;
				top: -1px;
				content: '/'; }

			&:last-child {
				&:after {
					display: none; } } } } }


