#choose {
	padding: 100px 0; }

.choose {
	margin-top: 50px; }

.choose-thumb {
	max-width: 500px;
	margin: 0 auto;

	img {
		width: 100%; } }
