#service {
	padding: 100px 0 50px; }

.feature-one {
	margin-bottom: 50px;

	.ser-icon {
		float: left;

		path {

			stroke: $color_theme; }

		color: $color_theme; }


	.content {
		margin-left: 80px; }

	h3 {
		margin-top: 0;
		text-transform: uppercase;
		color: #333;
		font-size: 20px;
		font-weight: 300;
		position: relative;
		padding-bottom: 10px;

		&:after {
			position: absolute;
			content: '';
			background: $color_theme;
			width: 50px;
			height: 2px;
			left: 0;
			bottom: 0; } } }


/*=========  Service two  ==========*/
.feature-three {
	margin-bottom: 50px;

	.icon-box {
		height: 60px;
		width: 60px;
		transition: all 0.3s ease-in-out;
		line-height: 75px;
		text-align: center;
		border-radius: 5px;
		color: #333;
		float: left;
		border: 1px solid $color_theme;
		border-radius: 5px;

		i {
			font-size: 30px;
			color: $color_theme; } }


	.content {
		margin-left: 70px;
		h3 {
			font-size: 18px;
			font-weight: 600;
			text-transform: uppercase;
			margin-top: 10px;
			position: relative;
			padding-bottom: 5px;

			&:after {
				position: absolute;
				left: 0;
				bottom: 0;
				content: '';
				background: $color_theme;
				height: 2px;
				width: 50px; } } }

	&:hover {
		.icon-box {
			background: $color_theme;

			i {
				color: #FFF; } } } }
