#contact {
	background: #2a2a2a; }

.contact-form-area {
	padding: 70px 0; }


.copy-right {
	padding: 25px;
	background: #212121;

	p {
		margin: 0;
		color: #969696;
		font-size: 14px;
		font-weight: 600;

		a {
			color: $color_theme;
			text-decoration: none; } } }

.contact-title {
	font-size: 17px;
	font-weight: 600;
	color: #FFF;
	position: relative;
	padding-bottom: 10px;
	margin-top: 0;

	&:after {
		position: absolute;
		left: 0;
		bottom: 0;
		content: '';
		width: 40px;
		height: 2px;
		background: $color_theme; } }

.con-details {
	margin-bottom: 30px;

	p {
		color: #ababab;
		margin-bottom: 0;
		font-size: 15px; } }

.get-in-touch {
	h2 {
		font-size: 20px;
		font-weight: 600;
		color: #FFF;
		position: relative;
		padding-bottom: 10px;
		margin-top: 0;
		text-transform: uppercase;
		margin-bottom: 30px;

		&:after {
			position: absolute;
			left: 0;
			bottom: 0;
			content: '';
			width: 40px;
			height: 2px;
			background: $color_theme; } } }

#gp-contact-form {
	.form-control {
		background: transparent;
		border-radius: 0;
		height: 40px;
		border: 1px solid #555;
		transition: all 0.3s ease-in-out;

		&:hover, &:focus {
			box-shadow: none;
			border-color: $color_theme; } }

	textarea.form-control {
		height: 150px; } }
