#pricing {
	padding: 100px 0;
	background: #f2f2f2; }


.pricing-item {
	border: 1px solid #f5f5f5;
	text-align: center;
	padding-bottom: 30px;
	box-shadow: 0 5px 31px rgba(0,0,0,0.1);
	transition: all 0.3s ease-in-out;
	position: relative;
	background: #fff;
	max-width: 400px;

	.price-active {
		position: absolute;
		right: 0px;
		top: 19px;
		background: #ff6863;
		z-index: 100;
		padding: 7px 10px 7px 25px;
		font-size: 14px;
		color: #FFF;
		transform: rotate(-90deg);

		&:after {
			width: 0;
			height: 0;
			border-top: 14px solid transparent;
			border-bottom: 14px solid transparent;
			border-left: 14px solid #f7f7f7;
			position: absolute;
			content: '';
			top: 0;
			left: 0; } }


	.pricing-head {
		height: 150px;
		background: #f7f7f7;
		padding: 20px 0;
		position: relative;
		margin-bottom: 70px;

		h3 {
			margin-top: 0;
			text-transform: uppercase;
			color: $color_theme;
			font-weight: 700; }

		.price {
			height: 110px;
			width: 110px;
			border-radius: 50%;
			background: $color_theme;
			line-height: 20px;
			position: absolute;
			left: 50%;
			bottom: -50px;
			transform: translateX(-50%);
			padding: 30px 0;

			&:before {
				position: absolute;
				content: '';
				left: 5px;
				top: 5px;
				height: 100px;
				width: 100px;
				border: 2px solid #FFF;
				border-radius: 50%; }

			h5 {
				color: #FFF;
				font-size: 30px;
				margin: 0;
				font-weight: 700; }

			span {
				color: #fff; } } }

	.price-feature {
		margin-bottom: 20px;
		.price-feature-item {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				padding: 5px 0;
				font-size: 15px; } } }

	.gp-btn {
		padding: 12px 40px;
		border-radius: 2px;
		background: $color_theme;

		&:before {
			transition: all 0.3s ease-in-out; }

		&:hover {
			&:before {
				background: #333; } } }

	&:hover {
		box-shadow: 0 25px 30px rgba(0,0,0,0.1);
		transform: translateY(-5px); } }
