#countup {
	padding: 100px 0;
	background-size: cover; }

.counter-box-two {
	i {
		font-size: 50px;
		color: $color_theme; }


	h2 {
		font-size: 50px;
		font-weight: 900;
		color: #FFF;
		margin-bottom: 0;
		line-height: 50px; }

	p {
		color: #f7f7f7;
		font: 16px; } }
