#news-letter {
	padding: 100px 0;
	text-align: center;
	background-size: cover; }

.news-letter {
	h2 {
		color: #FFF;
		text-transform: uppercase;
		font-size: 36px;
		margin-top: 0;
		margin-bottom: 20px;
		font-weight: 700; }

	p {
		margin-bottom: 30px;
		color: #FFF; } }

.news-form {
	input {
		width: 400px;
		height: 48px;
		background: transparent;
		border: 1px solid #ccc;
		padding: 10px;
		color: #FFF;
		outline: none;
		font-size: 16px; }

	.gp-btn {
		border: none;
		background: #FFF;
		color: #000;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		outline: none;


		&:hover, &:focus {
			background: #fff;
			color: #fff; } } }
