#work-together {
	padding: 100px 0;
	text-align: center; }

.together {
	h2 {
		color: #FFF;
		text-transform: uppercase;
		font-size: 28px;
		margin-bottom: 20px; }

	p {
		color: #ccc;
		margin-bottom: 30px; }

	.gp-btn {
		padding: 13px 30px;
		background: #FFF;
		color: #000;

		&:hover {
			color: #FFF; } } }


