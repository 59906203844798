@media screen and (max-width: 1440px) {

	/*=========  Banner  ==========*/

	/* Banner Image */
	#banner-image {
		.banner-content {
			h2 {
				font-size: 70px; } } }

	/* Slider */
	.slider-caption-one {
		h2 {
			font-size: 80px;
			line-height: 80px; } } }


@media screen and (max-width: 1024px) {

	/*=========  Banner  ==========*/
	#banner-image {
		.banner-content {
			h2 {
				font-size: 70px; }

			h4 {
				font-size: 18px;
				letter-spacing: 0; } } }

	.video-content {
		h2 {
			font-size: 50px; } } }

@media screen and (max-width: 768px) {

	/*=========  Header  ==========*/
	#header {
		background: rgba(0,0,0,0.8);

		&.navbar-small {
			.navbar-toggle {
				.icon-bar {
					background: #000; } }
			.nav {
				li {
					a {
						padding: 8px 15px; } } } } }

	.navbar-brand {
		padding: 5px 20px 5px 0;

		img {
			width: 120px; } }

	.nav {
		> li {
			> a {
				padding: 10px; } } }

	.nav > li.search-btn a:before {
		display: none; }


	/*=========  Banner  ==========*/

	.gp-btn {
		padding: 10px 20px;

		&.gp-bn-active {
			padding: 10px 20px; } }

	.slider-caption-one {
		h2 {
			font-size: 40px;
			line-height: 30px; }

		h3 {
			font-size: 18px;
			margin-bottom: 15px; } }

	.switcher {
		bottom: 20px; }


	#banner-image {
		.banner-content {
			h2 {
				font-size: 50px; }

			h4 {
				margin: 7px 0 20px; } } }

	.video-content {
		h2 {
			font-size: 35px; }

		p {
			margin-bottom: 20px; } }

	/*=========  About  ==========*/

	#about {
		padding: 50px 0; }

	.about-us {
		.gp-btn {
			margin-bottom: 20px; } }

	.ab-feature {
		max-width: 400px;
		margin: 0 auto 20px; }

	#history {
		padding: 50px 0;

		.gp-btn {
			margin-bottom: 30px; } }

	.feature-two {
		max-width: 400px;
		margin: 0 auto 30px; }

	.tab {
		margin-bottom: 50px; }

	/*=========  Video  ==========*/
	#video {
		padding: 70px 0; }


	/*=========  Service  ==========*/
	#service {
		padding: 50px 0; }

	.feature-one {
		max-width: 400px;
		margin: 0 auto 30px; }

	.feature-three {
		max-width: 400px;
		margin: 0 auto 30px; }

	/*=========  Countbox  ==========*/
	#countup {
		padding: 50px 0; }

	.counter-box-two {
		margin-bottom: 30px; }

	/*=========  Team  ==========*/
	.team-members {
		margin: 0 auto 20px;

		.merber-profile {
			left: 59%;
			top: 30%; } }

	/*=========  Pricing  ==========*/
	.pricing-item {
		margin: 0 auto 20px; }


	/*=========  Blog Grid  ==========*/
	.blog {
		margin: 0 auto 20px; }

	/*=========  News Letter  ==========*/
	.news-form {
		input {
			width: 350px; } } }

@media screen and (max-width: 500px) {

	/*=========  Banner  ==========*/
	.slider-caption-one {
		h2 {
			font-size: 35px;
			line-height: 28px; }

		h3 {
			font-size: 16px; } }

	#banner-image {
		.banner-content {
			h2 {
				font-size: 40px; } } }

	.video-content {
		p {
			br {
				display: none; } } }


	/*=========  About  ==========*/
	.tabs {
		li {
			margin-right: 5px;

			a {
				padding: 10px; } } }

	.mission-feature {
		li {
			font-size: 13px;
			line-height: 20px; } }


	/*=========  Portfolio  ==========*/
	#filter {
		padding-left: 0;
		li {
			a {
				padding: 10px; } } }

	/*=========  Testimonial  ==========*/
	.testimonial {
		.content {
			p {
				br {
					display: none; } } } }


	/*=========  Newsletter  ==========*/
	.news-form {
		input {
			width: 200px; } }

	.news-form {
		.gp-btn {
			padding: 15px 16px; } } }
