#team {
	padding: 100px 0;
	background: #f5f5f5; }

.team-members {
	position: relative;
	box-shadow: 0 3px 20px rgba(50,50,50,0.5);
	max-width: 400px;
	margin: 0 auto 20px;


	.team-thumb {
		img {
			width: 100%; } }

	.content {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.5);
		opacity: 0;
		transition: all 0.3s ease-in-out; }

	.merber-profile {
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		left: 50%;
		top: 20%;
		transform: translate(-50%,-50%);
		width: 43%;

		&:after ,&:before {
			position: absolute;
			height: 2px;
			width: 0;
			content: '';
			background: $color_theme;
			top: 51px;
			left: 53px;
			transition: all 0.5s ease-in-out;
			width: 1px; }

		&:before {
			transform: rotate(-45deg); }

		&:after {
			transform: rotate(45deg); }


		li {
			width: 50%;
			height: 35px;
			width: 35px;
			position: relative;
			text-align: center;
			line-height: 35px;
			margin-bottom: 5px;
			transition: all 0.3s ease-in-out;
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 45px;
			position: absolute;

			&:nth-child(1) {
				left: 35px;
				top: -10px; }


			&:nth-child(2) {
				left: 82px;
				top: 34px; }

			&:nth-child(3) {
				left: -10px;
				top: 34px; }

			&:nth-child(4) {
				left: 35px;
				top: 80px; }

			a {
				color: #FFF;
				display: block;
				cursor: pointer; }

			&:before {
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				background: transparent;
				height: 100%;
				width: 100%;
				transform: rotate(-45deg);
				z-index: -1;
				transition: all 0.3s ease-in-out; }

			&:after {
				position: absolute;
				content: '';
				height: 35px;
				width: 35px;
				border: 1px solid #FFF;
				left: 0;
				top: 0;
				transform: rotate(-45deg); }

			&:hover {

				a {
					color: #FFF;

					i {
						z-index: 100; } }

				&:before {
					background: $color_theme; }

				&:after {
					border-color: $color_theme; } } } }

	.details {
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		text-align: center;
		width: 100%; }

	.name {
		transform: translateY(15px);
		font-size: 18px;
		margin: 0px;
		color: $color_theme;
		transition: all 0.3s ease-in-out; }

	.position {
		font-size: 15px;
		color: #FFF;
		transform: translateY(15px);
		transition: all 0.5s ease-in-out;
		margin-bottom: 0; }

	&:hover {
		.content {
			opacity: 1;

			.merber-profile {

				&:after ,&:before {

					width: 100px;
					top: 51px;
					left: 3px; }

				li {
					&:nth-child(1) {
						top: 0; }

					&:nth-child(2) {
						left: 72px; }

					&:nth-child(3) {
						left: 0; }

					&:nth-child(4) {
						top: 70px; } } }

			.name, .position {
				transform: translateY(0); } } } }
