#header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 1000;
	border-bottom: 1px solid #333;

	&.navbar-small {
		position: fixed;
		background: #FFF;
		border-bottom: none;
		box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);

		.nav {
			li {
				&.active {

					a {
						color: $color_theme; } }
				a {
					color: #000;
					padding: 20px 15px;

					&:hover {
						color: $color_theme; } } } }

		.navbar-brand {
			padding: 5px 30px 5px 0;
			border-color:  #ccc;

			.logo-light {
				display: none; }

			.logo-dark {
				display: block; } } } }
.navbar {
	margin: 0;
	border-bottom: none; }

.navbar-brand {
	height: auto;
	padding: 15px 30px 15px 0;
	border-right: 1px solid #333;

	.logo-light {
		display: block; }

	.logo-dark {
		display: none; } }

.nav {
	> li {
		> a {
				color: #FFF;
				padding: 30px 15px;
				text-transform: uppercase;
				font-size: 12px;

				&:focus, &:hover {
					background-color: transparent;
					color: $color_theme; } }

		&.search-btn {
			a {
				position: relative;

				&:before {
					position: absolute;
					left: 0;
					top: 32px;
					height: 14px;
					width: 2px;
					background: #FFF;
					content: ''; } } } } }


.navbar-toggle {
	margin-right: 0;

	.icon-bar {
		background: #FFF; } }
