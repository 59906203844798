#portfolio {
	position: relative;
	padding-top: 100px; }

#projects-grid {
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	padding-top: 25px; }

#projects-grid {
	&:after {
		content: '';
		display: block;
		clear: both; } }


.portfolio-item {
	position: relative;
	float: left;
	width: 33.3333333333%;
	display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	margin-left: -1px;
	transform: translate3d(1px,0,0);

	h3 {
		position: absolute;
		left: 25px;
		bottom: 40px;
		letter-spacing: 1px;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: left;
		z-index: 3;
		color: #fff;
		opacity: 0;
		text-transform: uppercase;
		transition: all 300ms linear;

		span {
			font-weight: 300;
			font-size: 10px;
			letter-spacing: 2px; } }

	img {
		width: 100%;
		display:block {}
		-webkit-transition: all 300ms linear;
		-moz-transition: all 300ms linear;
		-o-transition: all 300ms linear;
		-ms-transition: all 300ms linear;
		transition: all 300ms linear; }

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: #212121;
		opacity: 0;
		-webkit-transition: all 200ms linear;
		-moz-transition: all 200ms linear;
		-o-transition: all 200ms linear;
		-ms-transition: all 200ms linear;
		transition: all 200ms linear; }


	&:hover {
		h3 {
			left: 40px;
			opacity: 1; }

		img {
			-webkit-transform: scale(1.05);
			-moz-transform: scale(1.05);
			-ms-transform: scale(1.05);
			-o-transform: scale(1.05);
			transform: scale(1.05); }

		.overlay {
			opacity:.9 {} } } }


#portfolio-filter {
	text-align: center;
	z-index: 20;
	border-radius: 30px; }


#filter {
	position: relative;
	width: 100%;
	text-align: center;
	li {
		display: inline-block;
		text-align: center;

		a {
			position: relative;
			display: inline-block;
			letter-spacing: 1px;
			font-weight: 400;
			padding: 10px 20px;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			font-size: 12px;
			text-align: center;
			outline: none;
			color: #fff;
			border-radius: 2px;
			background-color: #212121;
			text-decoration: none;
			transition: all 300ms linear;
			text-transform: uppercase;

			&.current ,a:hover {
				background: $color_theme; } } }

	&:after {
		content: '';
		display: block;
		clear: both; } }


.isotope-item {
	z-index: 2; }
.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1; }
.isotope, .isotope .isotope-item {
	transition-duration: 0.6s; }

.isotope {
	transition-property: height, width; }

.isotope .isotope-item {

	transition-property: transform, opacity; }


.portfolio {
	width: 100%;
	height: auto;
	margin: 0 auto;
	max-width: 1320px; }

.expander-wrap {
	position: relative;
	display: none;
	text-align: center; }

#expander-wrap {
	position: relative;
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
	overflow: hidden; }



/* Expander Item Controls */

.project-controls {
	position: relative;
	width: 100%;
	height: auto;
	top: 50px;
	max-width: 1320px;
	margin: 0 auto; }

.expander-inner {
	position: relative; }

.cls-btn {
	width: 100%;
	padding: 0;
	margin: 0 auto;
	display: block;
	height: auto; }

.expander-wrap {
	.cls-btn {
		.close {
			position: relative;
			display: inline-block;
			color: #000;
			top: 0;
			margin-top: 60px;
			margin-bottom: 20px;
			width: 50px;
			height: 50px;
			background-image: url('../images/close.png');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 40px 40px;
			text-align: center;
			cursor: pointer;
			float: none;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s; } } }

.expander-close {
	&:hover {
		cursor: pointer; } }

.expander-wrap {
	.cls-btn {
		.close {
			&:hover {
				background-size: 35px 35px; } } } }
