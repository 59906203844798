body {
	font-size: 14px;
	color: #000;
	font-family: 'Lato', sans-serif; }


h1,h2,h3,h4,h5 {
	font-family: 'Montserrat', sans-serif; }


.starter-template {
	padding: 40px 15px;
	text-align: center; }

p {
	line-height: 24px; }
a {
	transition: all 0.15s ease-in-out; }
a,
a:hover {
	text-decoration: none; }

.no-padding {
	padding-bottom: 0; }

.gp-btn {
	display: inline-block;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	transition-property: color;
	transition-duration: 0.5s;
	padding: 14px 35px;
	cursor: pointer;
	background: #000;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border: none;
	border-radius: 3px;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color_theme;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.5s;
		transition-duration: 0.5s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out; }

	&.gp-bn-active {
		background: $color_theme;
		padding: 15px 50px;

		&:before {
			background: #000; } }

	&.btn-radius {
		border-radius: 30px;
		transition: all 0.3s ease-in-out;
		text-transform: uppercase;


		&:before {
			display: none; }


		&:hover {
			background: $color_theme; } }


	&:hover ,&:focus {
		color: #FFF;
		text-decoration: none;

		&:before {
			transform: scaleX(1);
			-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
			transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); } } }

.gp-active {
	background: $color_theme;
	margin-right: 20px; }

.section-title {
	position: relative;

	h2 {
		color: #000;
		font-weight: 800;
		font-size: 30px;
		text-transform: uppercase;
		margin-top: 0;
		position: relative;
		z-index: 100;
		margin-bottom: 30px;
		position: relative;
		display: inline-block;


		&:before {
			position: absolute;
			content: '';
			background: $color_theme;
			width: 50px;
			height: 4px;
			left: 50%;
			bottom: -18px;
			z-index: 100;
			transform: translateX(-50%); }


		&:after {
			position: absolute;
			content: '';
			background: #c5c5c5;
			width: 100%;
			height: 1px;
			right: 0;
			bottom: -17px; } }


	p {
		margin-bottom: 50px; }

	&.style-left {
		h2 {
			&:before {
				left: 0;
				transform: translateX(0); } } } }

/*=========  Remove This Css  ==========*/

.demo-page {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 20px;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			transition: all 0.3s ease-in-out; }


		a {
			display: block;
			text-align: center;
			color: #FFF;

			img {
				width: 100%; }

			strong {
				z-index: 100;
				position: absolute;
				top: 50%;
				width: 100%;
				left: 0;
				opacity: 0;
				transition: all 0.3s ease-in-out;
				text-transform: uppercase;
				font-size: 15px;
				font-weight: 700; } }

		&:hover {
			&:after {
				background: rgba(0,0,0,0.8); }

			a {
				strong {

					opacity: 1;
					transform: translateY(-50%); } } } } }

.Switcher__control {
	background-image: none;
	background: $color_theme;

	i {

		font-size: 30px;
		color: #FFF; } }


/*=========  Page Loader  ==========*/
#loader {
	width: 100%;
	height: 100%;
	background: #333;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 999999; }

&.loaded {
	display: none; }


.loader {
	position: absolute;
	left: 50%;
	top: 50%; }


.loader--glisteningWindow {
	width: 0.25em;
	height: 0.25em;
	box-shadow: 0.70711em 0.70711em 0 0em #2ecc71, -0.70711em 0.70711em 0 0.17678em #9b59b6, -0.70711em -0.70711em 0 0.25em #3498db, 0.70711em -0.70711em 0 0.17678em #f1c40f;
	animation: gw 1s ease-in-out infinite, rot 2.8s linear infinite; }

@keyframes rot {
	to {
		transform: rotate(360deg); } }


@keyframes gw {
	0% {
		box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; }

	25% {
		box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.5em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.125em #f1c40f; }

	50% {
		box-shadow: 0.70711em 0.70711em 0 0.5em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.125em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; }

	75% {
		box-shadow: 0.70711em 0.70711em 0 0.39017em #2ecc71, -0.70711em 0.70711em 0 0.125em #9b59b6, -0.70711em -0.70711em 0 0.39017em #3498db, 0.70711em -0.70711em 0 0.5em #f1c40f; }

	100% {
		box-shadow: 0.70711em 0.70711em 0 0.125em #2ecc71, -0.70711em 0.70711em 0 0.39017em #9b59b6, -0.70711em -0.70711em 0 0.5em #3498db, 0.70711em -0.70711em 0 0.39017em #f1c40f; } }

