/* Banner */

.swiper-banner {
	position: relative;
	overflow: hidden;
	height: 100vh;

	.swiper-slide {
		.slider-caption-one, .slider-caption-two, .slider-caption-three {
			opacity: 0;
			transition-delay: .7s; } }


	.swiper-slide-active {
		.slider-caption-one, .slider-caption-two, .slider-caption-three {
			opacity: 1; } }

	.swiper-slide {
		background-size: cover;

		> img {
			width: 100%;
			height: auto; }

		.slider-caption {
			h3 {
				font-size: 20px;
				color: #FFF;
				text-transform: uppercase;
				font-weight: 700;
				margin-top: 0;
				text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
				margin-top: 0;
				margin-bottom: 30px;
				text-transform: uppercase; }


			h2 {
				font-size: 50px;
				color: #FFF;
				font-weight: 700;
				text-transform: uppercase;
				text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
				margin-top: 0;
				margin-bottom: 40px; }

			p {
				color: #FFF;
				line-height: 26px;
				margin-bottom: 50px; } } } }


.banner-next,.banner-prev {
	top: 50%;
	position: absolute;
	z-index: 500;
	color: #FFF;
	background: rgba(0,0,0, 0.7);
	height: 50px;
	width: 50px;
	line-height: 60px;
	transition: all 0.3s ease;
	text-align: center;
	opacity: 0;
	overflow: hidden;
	cursor: pointer;

	.next-btn, .prev-btn {
		i {
			font-size: 40px; } }

	&:hover {
		background: $color_theme; } }

.banner-prev {
	border-bottom-right-radius: 7px;
	border-top-right-radius: 7px; }

.banner-next {
	right: 0%;
	border-bottom-left-radius: 7px;
	border-top-left-radius: 7px; }


.swiper-pagination {
	text-align: right;
	right: 100px;
	left: auto !important; }

.swiper-pagination-bullet {
	width: 3px;
	height: 20px;
	border-radius: 0;
	background: #FFF;
	opacity: 1;
	margin: 0 2px !important; }

.swiper-pagination-bullet-active {
	background: $color_theme;
	height: 25px; }

.overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0, 0.5); }


.slider-caption-one {
	text-align: center;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%,-50%);
	width: 100%;
	position: absolute;

	h2 {
		font-size: 100px;
		color: #FFF;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 0;
		position: relative;
		display: block;
		line-height: 100px;
		letter-spacing: 1px; }

	h3 {
		font-size: 22px;
		color: #FFF;
		margin-bottom: 50px;
		letter-spacing: 1px; }

	h4 {
		font-size: 20px;
		color: #FFF; } }

.slider-caption-two {
	position: absolute;
	left: 15%;
	top: 50%;
	transform: translateY(-50%);

	h4 {
		color: #FFF;
		font-size: 28px;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 2px; }

	h2 {
		font-size: 70px;
		line-height: 70px;
		color: #FFF;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
		margin-top: 0; }

	p {
		color: #FFF;
		font-size: 16px;
		margin: 20px 0 40px; } }

.slider-caption-three {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	text-align: center;


	h3 {
		color: #fff;
		font-weight: 600;
		font-size: 32px;
		letter-spacing: 1px;
		margin-bottom: 30px;
		font-weight: 600; }

	h4 {
		color: #FFF;
		font-size: 22px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px; }


	h2 {
		font-size: 60px;
		color: #FFF;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 25px; }

	p {
		color: #ddd;
		font-size: 15px;
		margin-bottom: 30px; } }


.switcher {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 500;
	display: block;
	animation: move 2.5s infinite;
	i {
		color: #FFF;
		font-size: 30px; }

	&:hover {
		text-decoration: none; } }


.swiper-banner {
	&:hover {
		.banner-next,.banner-prev {
			opacity: 1; } } }

@keyframes move {
	0% {
		transform: translateY(0); }
	50% {
		transform: translateY(25px); }
	100% {
		transform: translateY(0px); } }

.tp-bannertimer {
	display: none; }

/*=========  Banner Video  ==========*/
#banner-video {
	height: 100vh;
	position: relative;

	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.7); } }

.video-content {
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 100%;

	h2 {
		font-size: 60px;
		text-transform: uppercase;
		font-weight: 700;
		color: #FFF;

		span {
			color: $color_theme; } }

	p {
		color: #bbb;
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 40px; } }

/*=========  Banner Image  ==========*/
#banner-image {
	height: 100vh;
	position: relative;
	background-size: cover;

	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3); }

	.banner-content {
		text-align: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 100%;

		h3 {
			font-size: 30px;
			color: #FFF;
			margin: 0;
			font-weight: 700; }


		h2 {
			color: #FFF;
			font-size: 110px;
			margin: 0;
			font-weight: 700; }

		h4 {
			font-size: 20px;
			color: #ddd;
			margin: 20px 0 30px;
			letter-spacing: 3px; } } }
