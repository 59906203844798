#skills {
	padding: 100px 0; }

.skills {
	padding-bottom: 50px;
	border-bottom: 2px solid #dedede; }

.count-items {
	margin-top: 50px; }


.progress {
	border-radius: 0;
	height: 5px;
	margin-bottom: 40px;
	overflow: visible;
	box-shadow: none;
	background: #eee;
	margin-bottom: 46px;

	&.progress-two {
		border: 1px solid #bfbfbf;
		background-color: transparent;
		height: 10px;

		.progress-bar {
			height: 4px;
			margin: 2px;
			margin-bottom: 50px;

			&:after {
				display: none; } } } }

.skill-bar {
	text-align: left !important;
	margin: 0 !important;
	padding-left: 0;
	list-style: none;

	li {
		.skill-bar-wrap {
			margin-bottom: 20px;
			box-shadow: none; }

		.progress-bar {
			height: 5px;
			width: 0;
			display: block;
			text-align: right;
			overflow: visible !important;
			position: relative;
			box-shadow: none;
			border-radius: 0 !important;
			opacity: 1;
			background: $color_theme;

			&:after {
				position: absolute;
				top: -8px;
				right: 5px;
				content: '';
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 8px solid $color_theme; } }


		.skill-name, .percentage {
			color: #000;
			position: relative;
			top: -25px; }

		.skill-name {
			position: absolute;
			left: 0;
			font-weight: 700;
			font-size:  14px; } } }


.counter-box {
	.count {
		h2 {
			font-size: 40px;
			font-weight: 800;
			padding-bottom: 10px;
			position: relative;

			&:after {
				position: absolute;
				content: '';
				left: 50%;
				bottom: 0;
				height: 2px;
				width: 40px;
				background: $color_theme;
				transform: translateX(-50%); } }

		p {
			color: #333;
			font-weight: 600;
			font-style: italic; } } }
